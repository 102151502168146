import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import PlanFromOffer, { CodeMap } from '@core/types/plan';
import { Subject, BehaviorSubject, switchMap, tap } from 'rxjs';
import CodeResponse from '../../types/plan';
import { SaveEventName } from '@core/enums/save-event-name.enum';
import { environment } from '@environment/environment';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    private _templates$ = new Subject<{ externalId: string }[]>();
    private _plans$ = new Subject<PlanFromOffer>();
    private _updateTab$ = new Subject<SaveEventName>();
    initialProductDialogState = {
        riskBenefit: { component: '', updated: false },
        savingContribution: { component: '', updated: false },
    };
    private _productDialog$ = signal(this.initialProductDialogState);
    constructor(private _http: HttpClient) {}

    get apiUrl(): string {
        // return 'http://dockerrun.claninfo.ch:5808/dataservice/offertool'
        // return 'http://localhost:5800/dataservice/offertool';
        return `${localStorage.getItem('api-url')}/offertool`;
    }
    setProductDialogUpdates(value) {
        this._productDialog$.update((currentState) => ({
            ...currentState,
            ...value,
        }));
    }

    getProductDialogUpdates() {
        return this._productDialog$();
    }
    resetProductDialogUpdates() {
        return this._productDialog$.update((currentState) => ({
            ...currentState,
            ...this.initialProductDialogState,
        }));
    }
    getTemplates() {
        return this._http.get<{ externalId: string }[]>(`${this.apiUrl}/templates`).pipe(
            tap((templates) => {
                this._templates$.next(templates);
            })
        );
    }

    getPlanByOffer(offerId) {
        const path = `${this.apiUrl}/offers/${offerId}/plans`;

        return this._http.get<PlanFromOffer>(path).pipe(
            tap((plans) => {
                this._plans$.next(plans);
            })
        );
    }

    getCodesByPlanSpecId<T>(planSpecId) {
        const path = `${this.apiUrl}/plans/${planSpecId}/codes`;
        return this._http.get<CodeResponse[]>(path);
    }

    getCodesByName<T>(name: string) {
        const path = `${this.apiUrl}/plans/codes?name=${name}`;
        return this._http.get<CodeMap>(path);
    }

    getPlanSpecByOfferAndName<T>(offerId, name) {
        const path = `${this.apiUrl}/plans/${name}/offer/${offerId}`;
        return this._http.get<any>(path);
    }

    getTarrifs(offerId: number, name: string) {
        const path = `${this.apiUrl}/offers/${offerId}/tariffs?name=${name}`;
        return this._http.get<any>(path);
    }

    getTarrifDetails(offerId: number, category: string) {
        const path = `${this.apiUrl}/offers/${offerId}/plans/${category}/validity`;
        return this._http.get<any>(path);
    }

    postTariffs(offerId: number, tariffValues, category: string) {
        const path = `${this.apiUrl}/tariff-values/batch`;
        return this.getTarrifDetails(offerId, category).pipe(
            switchMap(({ validityId }) => {
                return this._http.post<any>(path, [
                    {
                        tariffValue: validityId,
                        ...tariffValues,
                    },
                ]);
            })
        );
    }

    deleteTariffValue(tariffValueId: number) {
        return this._http.delete(`${this.apiUrl}/tariff-values/${tariffValueId}`);
    }

    initializePlan(offerId: number, template: number) {
        const path = `${this.apiUrl}/offers/${offerId}/initialize?template=${template}&client=${environment.client}`;
        return this._http.post<any>(path, {});
    }

    changeAllPlanFieldValues<T>(offerId, value) {
        return this._http.put(`${this.apiUrl}/offers/${offerId}/plan`, value);
    }

    updateTariffValues(value) {
        return this._http.put(`${this.apiUrl}/tariff-values/batch`, value);
    }
    updatedForm(redirect: SaveEventName) {
        this._updateTab$.next(redirect);
        return this._updateTab$.asObservable();
    }
    getUpdatedFormSubs() {
        return this._updateTab$.asObservable();
    }

    getOTConfiguration() {
        const path = `${this.apiUrl}/configuration/${environment.client}`;
        return this._http.get<any>(path);
    }

    updateOTConfiguration(value) {
        const path = `${this.apiUrl}/configuration/${environment.client}`;
        return this._http.put<any>(path, { config: value, client: environment.client });
    }
}
